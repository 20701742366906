<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="900">
      <v-card>
        <v-card-text>
          <FormasPagos
            @GetEliminarPagos="GetEliminarPagos"
            @GetPagos="GetPagos"
            @AgregarPropinaVuelto="AgregarPropinaVuelto"
            @AgregarPropina="AgregarPropina"
            @EliminarPropina="EliminarPropina"
            :comanda="comanda"
            :monedas="monedas"
            :configuraciones="configuraciones"
            :tipoVisual="2"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import FormasPagos from '../../comanda/dashboard/componentes/FormasPagos.vue'

export default {
  props: {
    monedas: Array,
    comanda: Object,
    configuraciones: Array,
    value: Boolean,
  },
  components: { FormasPagos },
  watch: {
    dialog: function (_new, _old) {
      if (_new != _old) {
        this.retornar()
      }
      
    },
    value: function (_new, _old) {
      if (_new != this.dialog) {
        this.dialog = _new
      }
    },
   
  },
  setup(props, context) {
    const abrir = () => {
      dialog.value = true
    }

    const retornar = () => {
      context.emit('input', dialog.value)
    }

    const dialog = ref(false)
    const GetEliminarPagos = _dato => {
      context.emit('GetEliminarPagos', _dato)
    }
    const GetPagos = _dato => {
      context.emit('GetPagos', _dato)
    }
    const AgregarPropinaVuelto = _dato => {
      context.emit('AgregarPropinaVuelto', _dato)
    }
    const AgregarPropina = _dato => {
      context.emit('AgregarPropina', _dato)
    }
    const EliminarPropina = _dato => {
      context.emit('EliminarPropina', _dato)
    }

    return {
      GetEliminarPagos,
      GetPagos,
      AgregarPropinaVuelto,
      AgregarPropina,
      EliminarPropina,
      dialog,
      abrir,
      retornar
    }
  },
}
</script>

<style>
</style>