<template>
  <div>
    <v-row v-if="cargadoConfiguracion == 1">
      <v-col cols="8" class="my-0 py-0 px-0">
        <v-card class="contenedor-productos">
          <v-card-text>
            <v-row>
              <v-col>
                <h4 >
                  <v-icon>{{ icons.mdiCashRegister }} </v-icon> 
                  {{store.state.user.caja.nombre}} {{ serialFiscal }}
                </h4>
              </v-col>
              <v-col>
                <h4 class="text-center">
                  <v-icon>{{ icons.mdiAccountTie }} </v-icon> {{ comanda.mesonero_nombre }}
                  {{ comanda.mesonero_apellido }}
                </h4>
              </v-col>
              <v-col>
                <h4 class="text-center">
                  <v-icon>{{ icons.mdiTableChair }} </v-icon> {{ comanda.grupo_mesa }} {{ comanda.mesa_nombre }}
                </h4>
              </v-col>
             
              <v-col>
                <h4 class="text-right primary--text">
                  <v-icon>{{ icons.mdiInvoiceText }} </v-icon> {{ comanda.id <= 0 ? 'Nueva' : comanda.id }}
                </h4>
              </v-col>
            </v-row>
          </v-card-text>
          <v-simple-table height="calc(75vh - 236px)" ref="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Producto</th>
                  <th class="text-center">Cantidad</th>
                  <th class="text-right">Precio</th>
                  <th class="text-right">SubTotal</th>
                  <th class="text-right">Descuento</th>
                  <th class="text-right">Iva</th>
                  <th class="text-right">Total</th>
                  <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in comanda.productos"
                  :key="index"
                  :class="focusSelect == index ? 'grey2' : ''"
                >
                  <td @click="abrirModal(item)">
                    <div class="d-flex align-center">
                      <div class="d-flex flex-column">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <span
                                v-if="item.producto.nombre.length < 30"
                                class="d-block font-weight-semibold text--primary text-truncate"
                              >
                                {{ FuncionesGenerales.camelCase(item.producto.nombre) }}
                              </span>

                              <span v-else class="d-block font-weight-semibold text--primary text-truncate">
                                {{ FuncionesGenerales.camelCase(item.producto.nombre.substring(0, 27)) }}...
                              </span>
                            </span>
                          </template>
                          <span> {{ FuncionesGenerales.camelCase(item.producto.nombre) }}</span>
                        </v-tooltip>
                        <small>   <v-icon x-small >{{ icons.mdiBarcode }} </v-icon> {{ FuncionesGenerales.camelCase(item.producto.referencia) }} </small>
                        <small>  <v-icon x-small >{{ icons.mdiStorefrontOutline }} </v-icon>  {{ FuncionesGenerales.camelCase(item.producto.nombre_puesto) }} </small>

                        <small v-if="item.observacion">
                          <v-icon x-small >{{ icons.mdiEyeOutline }} </v-icon>  {{ FuncionesGenerales.camelCase(item.observacion.substring(0, 30)) }}
                        </small>
                      </div>
                    </div>
                  </td>
                  <td @click="abrirModal(item)" class="text-right">
                    <v-text-field
                      :disabled="item.id > 0"
                      @keyup="Totales()"
                      :id="'fieldId_' + index"
                      :ref="'fieldId_' + index"
                      dense
                      class="inputPrice centered-input"
                      style="width: 70px"
                      outlined
                      hide-details
                      type="number"
                      v-model="item.cantidad"
                    ></v-text-field>
                  </td>
                  <td @click="abrirModal(item)" class="text-right">
                    <span class="d-block font-weight-semibold text--primary text-truncate">
                      ${{ item.precio_real.toFixed(2) }}
                    </span>
                    <small class="primary--text"> {{ `${MonedaPais.simbolo} ${item.precio_real.toFixed(2)} ` }} </small>
                  </td>
                  <td @click="abrirModal(item)" class="text-right">
                    <span class="d-block font-weight-semibold text--primary text-truncate">
                      {{ `$.${round(Number(item.precio_real) * item.cantidad).toFixed(2)}` }}
                    </span>
                    <small class="primary--text">
                      {{
                        `${MonedaPais.simbolo}.${round(
                          round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad,
                        ).toFixed(2)}`
                      }}
                    </small>
                  </td>
                  <td @click="abrirModal(item)" class="text-right">
                    <span class="d-block font-weight-semibold text--primary text-truncate"
                      >{{
                        `$.${round((item.por_descuento / 100) * (Number(item.precio_real) * item.cantidad)).toFixed(2)}`
                      }}
                      <small class="error--text" v-if="item.por_descuento > 0">{{ item.por_descuento }}%</small>
                    </span>
                    <small class="primary--text">{{
                      `${MonedaPais.simbolo}.${(
                        round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad) *
                        (item.por_descuento / 100)
                      ).toFixed(2)}`
                    }}</small>
                  </td>
                  <td @click="abrirModal(item)" class="text-right">
                    <div class="d-flex flex-column">
                      <span class="d-block font-weight-semibold text--primary text-truncate">{{
                        `$.${round(
                          (round(round(item.precio_real) * item.cantidad).toFixed(2) -
                            round(round(round(item.precio_real) * item.cantidad) * (item.por_descuento / 100))) *
                            (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0),
                        ).toFixed(2)}`
                      }}</span>
                      <small class="primary--text">{{
                        `${MonedaPais.simbolo}.${round(
                          (round(round(item.precio_real * MonedaPais.tasa) * item.cantidad).toFixed(2) -
                            round(
                              round(round(item.precio_real * MonedaPais.tasa) * item.cantidad) *
                                (item.por_descuento / 100),
                            )) *
                            (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0),
                        ).toFixed(2)}`
                      }}</small>
                    </div>
                  </td>
                  <td @click="abrirModal(item)" class="text-right">
                    <span class="d-block font-weight-semibold text--primary text-truncate">{{
                      `$.${round(
                        (Number(item.precio_real) * item.cantidad -
                          round(round(round(Number(item.precio_real)) * item.cantidad) * (item.por_descuento / 100))) *
                          (1 + (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0)),
                      ).toFixed(2)}`
                    }}</span>
                    <small class="primary--text">{{
                      `${MonedaPais.simbolo}.${round(
                        round(
                          Number(item.precio_real) * MonedaPais.tasa * item.cantidad -
                            round(
                              round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad) *
                                (item.por_descuento / 100),
                            ),
                        ) +
                          round(
                            (Number(item.precio_real) * MonedaPais.tasa * item.cantidad -
                              round(
                                round(round(Number(item.precio_real) * MonedaPais.tasa) * item.cantidad) *
                                  (item.por_descuento / 100),
                              )) *
                              (!comanda.ind_exonerar_iva ? item.producto.impuesto_tasa_valor / 100 : 0),
                          ),
                      ).toFixed(2)}`
                    }}</small>
                  </td>
                  <td>
                    <v-btn
                      :disabled="item.id > 0"
                      icon
                      density="default"
                      color="secondary"
                      outlined
                      class="mr-1"
                      @click="editarProducto(item)"
                    >
                      <v-icon>{{ icons.mdiFileEdit }}</v-icon>
                    </v-btn>
                    <v-btn outlined icon color="error" @click="GetEliminarProducto(item)">
                      <v-icon>{{ icons.mdiDelete }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-card-text class="py-2 mr-0 pr-0 pr-2 text-right">
          <v-row>
            <v-col cols="4" class="mx-0 px-0 pr-2">
              <v-text-field
                ref="BuscarProductoRef"
                :prepend-inner-icon="icons.mdiBarcode"
                outlined
                hide-details
                label="Buscar Producto"
                v-model="TextReferencia"
                :loading="TextReferenciaLoading"
                v-on:keyup.enter="buscarProductoReferencia()"
              ></v-text-field>
            </v-col>
            <v-col class="mx-0 px-1" v-if="$vuetify.breakpoint.smAndUp">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="dialogProductos = true"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF1 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiFood }} </v-icon>
                    <br />
                    <small> <strong>Productos</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="mx-0 px-1" v-if="$vuetify.breakpoint.smAndUp">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="dialogDescuento = true"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF2 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiSale }} </v-icon>
                    <br />
                    <small> <strong>Descuento</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="mx-0 px-1" v-if="$vuetify.breakpoint.mdAndUp">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="dialogServicio = true"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF3 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiPercentOutline }} </v-icon>
                    <br />
                    <small> <strong>%Servicio</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="mx-0 px-1" v-if="$vuetify.breakpoint.lgOnly">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="abrirBuscarComandas()"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF4 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiStickerTextOutline }} </v-icon>
                    <br />
                    <small> <strong>Comanda</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>

            <v-col class="mx-0 px-1">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="info"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="dialogBtns = true"
                >
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiDotsHorizontal }} </v-icon>
                    <br />
                    <small> <strong>Mas</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card class="contenedor-totales">
          <v-row>
            <v-col cols="6">
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td class="text-left"><strong>SUBTOTAL: </strong></td>
                      <td class="text-right">
                        <span>$.{{ round(comanda.subtotal).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>{{ MonedaPais.simbolo }}.{{ round(comanda.subtotalMonedaPais).toFixed(2) }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="text-left">
                        <strong>DESC:({{ comanda.por_descuento }}%)</strong>
                      </td>
                      <td class="text-right">
                        <span>-$.{{ round(comanda.descuento).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>-{{ MonedaPais.simbolo }}.{{ round(comanda.descuentoMonedaPais).toFixed(2) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <strong>SERV({{ comanda.por_comision }}%):</strong>
                      </td>
                      <td class="text-right">
                        <span>+$ {{ comanda.servicio.toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>+{{ MonedaPais.simbolo }}.{{ round(comanda.servicioMonedaPais.toFixed(2)) }} </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left"><strong>IVA:</strong></td>
                      <td class="text-right">
                        <span>+$.{{ round(comanda.iva).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>+{{ MonedaPais.simbolo }}.{{ round(comanda.ivaMonedaPais).toFixed(2) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left"><strong>IGTF:</strong></td>
                      <td class="text-right">
                        <span>+$.{{ round(comanda.igtf).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span>+{{ MonedaPais.simbolo }}.{{ comanda.igtfMonedaPais.toFixed(2) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="6">
              <v-simple-table dense>
                <template>
                  <tbody>
                    <tr>
                      <td class="text-left"><strong>TOTAL:</strong></td>
                      <td class="text-right">$.{{ round(comanda.total).toFixed(2) }}</td>
                      <td class="text-right">
                        {{ MonedaPais.simbolo }}.{{ round(comanda.totalMonedaPais).toFixed(2) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left"><strong>PROPINA:</strong></td>

                      <td class="text-right">
                        <span class=" ">$.{{ round(totalesPropina().totalMoneda).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span class=" ">Bs.{{ round(totalesPropina().totalMonedaPais).toFixed(2) }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left"><strong>PAGOS:</strong></td>
                      <td class="text-right">
                        <span class=" ">$.{{ round(comanda.totalPagos).toFixed(2) }}</span>
                      </td>
                      <td class="text-right">
                        <span class=" "
                          >{{ MonedaPais.simbolo }}.{{ round(comanda.totalPagosMonedaPais).toFixed(2) }}</span
                        >
                      </td>
                    </tr>

                    <tr>
                      <td class="text-left"><strong>TOTAL REAL:</strong></td>
                      <td class="text-right">
                        <strong class=" "
                          >$.{{ round(totalesPropina().totalMoneda + comanda.total).toFixed(2) }}</strong
                        >
                      </td>
                      <td class="text-right">
                        <strong class=" "
                          >{{ MonedaPais.simbolo }}.{{
                            round(
                              totalesPropina().totalMonedaPais +
                                comanda.totalPagosMonedaPais +
                                (comanda.restaMonedaPais < 0 ? comanda.restaMonedaPais : 0),
                            ).toFixed(2)
                          }}</strong
                        >
                      </td>
                    </tr>

                    <tr>
                      <td class="text-left">
                        <strong :class="comanda.resta > 0 ? 'error--text' : 'success--text'">
                          {{ comanda.resta > 0 ? 'RESTA' : 'VUELTO' }}:</strong
                        >
                      </td>
                      <td class="text-right">
                        <span :class="comanda.resta > 0 ? 'error--text' : 'success--text'"
                          >$.{{ round(comanda.resta * -1).toFixed(2) }}</span
                        >
                      </td>
                      <td class="text-right">
                        <span :class="comanda.resta > 0 ? 'error--text' : 'success--text'"
                          >{{ MonedaPais.simbolo }}.{{ round(comanda.restaMonedaPais * -1).toFixed(2) }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-row>
          <v-col cols="12" class="mt-0 pt-0" style="height: 20vh">
            <v-card style="height: 20vh" @click="abrirCliente()">
              <v-card-text>
                <v-icon class="icon-tecla pr-2" color="black">{{ icons.mdiKeyboardF5 }}</v-icon>
                <v-row>
                  <v-col cols="4">
                    <v-img :src="require('@/assets/images/3d-characters/14.png')"></v-img>
                  </v-col>
                  <v-col>
                    <div v-if="comanda.id_cliente > 0">
                      <p class="py-0 my-0">#:{{ comanda.id_cliente }}</p>

                      <h3 class=" ">{{ comanda.nombre_cliente }} {{ comanda.apellido_cliente }}</h3>

                      <p class="py-0 my-0">{{ comanda.documento }}</p>
                      <p class="py-0 my-0">direccion</p>
                    </div>
                    <div v-else>
                      <h2>Cliente</h2>
                      <p class="py-0 my-0">Debe seleccionar un cliente</p>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" style="height: calc(80vh - 157px)">
            <v-card style="height: calc(80vh - 157px)">
              <v-btn @click="abrirFormaPago()" class="primary" block large>Forma Pagos</v-btn>
              <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF6 }}</v-icon>
              <v-card-text>
                <v-row>
                  <v-list width="100%">
                    <div class="text-center mt-4">
                      <small>Pagos</small>
                    </div>

                    <v-divider></v-divider>
                    <template v-for="(item, index) in comanda.pagos">
                      <v-list-item :key="`PAGO${index}`">
                        <v-list-item-avatar size="30">
                          <v-avatar
                            :color="item.punto_venta ? '' : 'primary'"
                            :class="item.punto_venta ? '' : 'v-avatar-light-bg white--text'"
                            size="32"
                          >
                            <v-img
                              v-if="item.punto_venta"
                              :src="config.api_imagenes + '/banco/img/s-' + item.punto_venta.banco.img_icon"
                            >
                            </v-img>
                            <span v-else> {{ item.moneda.simbolo }}</span>
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content class="p-0 m-0">
                          <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }}</v-list-item-subtitle>

                          <v-list-item-subtitle class="py-0 my-0"
                            >{{ item.moneda.tipo_pago.filter(it => it.id == item.tipo_pago)[0].nombre }}
                            {{ item.moneda.simbolo }}: {{ Number(item.monto_moneda).toFixed(2) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1"
                            ><small>Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="MonedaPais.simbolo != item.moneda.simbolo"
                            ><small
                              >Monto {{ MonedaPais.simbolo }}:
                              {{ (item.monto_real * MonedaPais.tasa).toFixed(2) }}</small
                            >
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="py-0 my-0" v-if="item.punto_venta">
                            <small>{{ item.punto_venta.banco.nombre }} | {{ item.punto_venta.codigo }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                            ><small> Ref: {{ item.referencia }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="py-0 my-0 success--text"
                            v-if="item.vuelto_info.monto_moneda > 0"
                          >
                            <small
                              >Vuelto
                              {{
                                item.vuelto_info.moneda.tipo_pago.filter(it => it.id == item.vuelto_info.tipo_pago)[0]
                                  .nombre
                              }}
                            </small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="py-0 my-0 success--text"
                            v-if="item.vuelto_info.monto_moneda > 0"
                          >
                            <small>
                              {{ item.vuelto_info.moneda.simbolo }}
                              {{ item.vuelto_info.monto_moneda.toFixed(2) }}</small
                            >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider :key="`PAGO${index}`"></v-divider>
                    </template>
                    <v-divider></v-divider>
                    <div class="text-center" v-if="comanda.propinas && comanda.propinas.length > 0">
                      <small> Propinas</small>
                    </div>
                    <v-divider></v-divider>
                    <template v-for="(item, index) in comanda.propinas">
                      <v-list-item :key="`propina${index}`" class="grey4">
                        <v-list-item-avatar size="30">
                          <v-avatar
                            :color="item.punto_venta ? '' : 'primary'"
                            :class="item.punto_venta ? '' : 'v-avatar-light-bg primary--text'"
                            size="32"
                          >
                            <v-img
                              v-if="item.punto_venta"
                              :src="config.api_imagenes + '/banco/img/s-' + item.punto_venta.banco.img_icon"
                            >
                            </v-img>
                            <span v-else class="white--text"> {{ item.moneda.simbolo }}</span>
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content class="p-0 m-0">
                          <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }}</v-list-item-subtitle>

                          <v-list-item-subtitle class="py-0 my-0">
                            <small v-if="item.id_pago_propina_vuelto"> PROPINA VUELTO <br /></small>
                            {{ item.moneda.tipo_pago.filter(it => it.id == item.tipo_pago)[0].nombre }}
                            {{ item.moneda.simbolo }}: {{ Number(item.monto_moneda).toFixed(2) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1"
                            ><small>Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="MonedaPais.simbolo != item.moneda.simbolo"
                            ><small
                              >Monto {{ MonedaPais.simbolo }}:
                              {{ (item.monto_real * MonedaPais.tasa).toFixed(2) }}</small
                            >
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.punto_venta">
                            <small>{{ item.punto_venta.banco.nombre }} | {{ item.punto_venta.codigo }}</small>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                            ><small> Ref: {{ item.referencia }}</small>
                          </v-list-item-subtitle>

                          <v-list-item-subtitle class="py-0 my-0">
                            <strong>
                              <small> {{ item.empleado_propina }}</small></strong
                            >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider :key="`propina${index}`"></v-divider>
                    </template>
                  </v-list>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-row class="mx-0">
              <v-col cols="6" lg="4" class="mr-1 px-0">
                <v-hover v-slot="{ hover }">
                  <v-card
                    class="error"
                    :elevation="hover ? 12 : 2"
                    :class="{ 'on-hover': hover }"
                    @click="cancelarFactura()"
                  >
                    <small class="icon-tecla pr-2 white--text">ALT+L</small>
                    <v-card-text class="text-center py-1 white--text">
                      <v-icon color="white">{{ icons.mdiCancel }} </v-icon>
                      <br />
                      <small> <strong>Cancelar</strong> </small>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col class="mx-0 px-0">
                <v-hover v-slot="{ hover }">
                  <v-card
                    :class="(comanda.productos.length > 0 && comanda.resta <= 0 ?'success ' :'secondary ') + { 'on-hover': hover } "
                    :elevation="hover ? 12 : 2"
                    :disabled="!(comanda.productos.length > 0   )"
                    
                    @click="GetGuardarComanda()"
                  >
                    <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF12 }}</v-icon>
                    <v-card-text class="text-center py-1 white--text">
                      <v-icon color="white">{{ icons.mdiInvoiceTextCheck }} </v-icon>

                      <br />
                      <small>
                        <strong>{{ comanda.productos.length > 0 && comanda.resta <= 0 ?'Finalizar ' :'Guardar ' }}</strong>
                      </small>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-else-if="cargadoConfiguracion == 0">Cargando Configuracion</div>
    <div v-else-if="cargadoConfiguracion == 2">Hubo un error al cargar la configuracion</div>

    <KeyBoardEvents v-on:keydown="keyboardEvent"></KeyBoardEvents>
    <v-dialog v-model="dialogBtns" width="500">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col class="mx-0 px-1">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="dialogProductos = true"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF1 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiFood }} </v-icon>
                    <br />
                    <small> <strong>Productos</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="mx-0 px-1">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="dialogDescuento = true"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF2 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiSale }} </v-icon>
                    <br />
                    <small> <strong>Descuento</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="mx-0 px-1">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="dialogServicio = true"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF3 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiPercentOutline }} </v-icon>
                    <br />
                    <small> <strong>%Servicio</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="mx-0 px-1">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="abrirBuscarComandas()"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF4 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiStickerTextOutline }} </v-icon>
                    <br />
                    <small> <strong>Comanda</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="mx-0 px-1">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="dialogMesa = true"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF7 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiTableChair }} </v-icon>
                    <br />
                    <small> <strong>Mesas</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="mx-0 px-1">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="CerrarSesion()"
                >
                  <v-icon class="icon-tecla pr-2" color="white">{{ icons.mdiKeyboardF8 }}</v-icon>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiAccountTie }} </v-icon>
                    <br />
                    <small> <strong>Mesonero</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="mx-0 px-1">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="primary"
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="AbrirComandaAntigua()"
                >
                  <span class="icon-tecla pr-2 white--text">ALT+F</span>
                  <v-card-text class="text-center py-1 white--text">
                    <v-icon color="white">{{ icons.mdiStickerTextOutline }} </v-icon>
                    <br />
                    <small> <strong>Nota Credito</strong> </small>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialogBtns = false"> cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogServicio" scrollable max-width="300px">
      <v-card>
        <v-card-title>Ingrese el % de servicio</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="_por_servicio"
            label="Descuento"
            :rules="[rules.required, rules.min]"
            type="number"
            outlined
            suffix="%"
            v-on:keyup.enter="GetCambiarComision(_por_servicio)"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialogServicio = false"> Cerrar </v-btn>
          <v-btn small color="primary" @click="GetCambiarComision(_por_servicio)"> Aplicar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDescuento" scrollable max-width="300px">
      <v-card>
        <v-card-title>Ingrese el % de descuento</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            autofocus
            v-model="_por_descuento"
            label="Descuento"
            :rules="[rules.required, rules.min]"
            type="number"
            outlined
            suffix="%"
            v-on:keyup.enter="GetCambiarPorDescuentoGeneral(_por_descuento)"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialogDescuento = false"> Cerrar </v-btn>
          <v-btn small color="primary" @click="GetCambiarPorDescuentoGeneral(_por_descuento)"> Aplicar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BuscarProducto @getProducto="GetAgregarProducto" v-model="dialogProductos" />
    <ModalConfirmacion ref="ModalConfirmacionRef" />
    <modal-cliente @GetClienteFull="GetClienteFull" ref="ModalClienteRef"></modal-cliente>

    <modal-forma-pago
      :comanda="comanda"
      :monedas="monedas"
      :configuraciones="configuraciones"
      v-model="dialogFormaPago"
      @GetEliminarPagos="GetEliminarPagos"
      @GetPagos="GetPagos"
      @AgregarPropinaVuelto="AgregarPropinaVuelto"
      @AgregarPropina="AgregarPropina"
      @EliminarPropina="EliminarPropina"
    ></modal-forma-pago>
    <v-navigation-drawer v-model="drawer" absolute temporary right width="500">
      <ListaDetallesProductosDetalle
        v-if="ProductoSelect.id"
        @GetAtrasProductoDetalle="GetAtrasProductoDetalle"
        @GetAgregarProducto="GetEditarProducto"
        :mesoneros="mesoneros"
        :producto="ProductoSelect"
      ></ListaDetallesProductosDetalle>
    </v-navigation-drawer>
    <BuscarComandas @GetVerComanda="GetVerComanda" ref="BuscarComandasRef" />
    <ModalVerComanda
    v-if="comandaSelect.id"
      :comanda="comandaSelect"
      :soloVer="true"
      :indFiscal="true"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
      :serialFiscal="serialFiscal"
      :monedas="monedas"
    />
    <modal-mesas
      :label="''"
      @GetMesa="GetMesa"
      v-model="dialogMesa"
      :verBtn="false"
    ></modal-mesas>

    <v-dialog v-model="loadGuardandoComanda" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Guardando Espere un momento
              <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiPoll,
  mdiLabelVariantOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiShopping,
  mdiCashRegister,
  mdiCached,
  mdiDelete,
  mdiBarcode,
  mdiFood,
  mdiDotsHorizontal,
  mdiPercentOutline,
  mdiSale,
  mdiCancel,
  mdiKeyboardF12,
  mdiKeyboardF1,
  mdiKeyboardF2,
  mdiKeyboardF3,
  mdiKeyboardF4,
  mdiKeyboardF5,
  mdiKeyboardF6,
  mdiKeyboardF7,
  mdiKeyboardF8,
  mdiKeyboardF9,
  mdiKeyboardF10,
  mdiKeyboardF11,
  mdiKeyboardEsc,
  mdiKeyboardCaps,
  mdiFileEdit,
  mdiAccountTie,
  mdiTableChair ,
  mdiStorefrontOutline,
mdiEyeOutline,
mdiPrinter
} from '@mdi/js'

import { onBeforeMount, ref, watch } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import FormasPagos from '../comanda/dashboard/componentes/FormasPagos.vue'
import ComandaService from '@/api/servicios/ComandaService'
import { acciones } from '@/modulos'
import FuncionesGenerales from '@/funciones/funciones'
import KeyBoardEvents from '@/components/KeyBoardEvents.vue'
import BuscarProducto from './componentes/BuscarProducto.vue'
import ModalConfirmacion from '@/components/ModalConfirmacion.vue'
import ModalCliente from '../comanda/dashboard/componentes/ModalCliente.vue'
import ProductosService from '@/api/servicios/ProductosService'
import ListaDetallesProductosDetalle from '../comanda/dashboard/componentes/ListaDetallesProductosDetalle.vue'
import BuscarComandas from './componentes/BuscarComandas.vue'
import ImpresoraService from '@/api/servicios/ImpresoraService'
import SeguridadService from '@/api/servicios/SeguridadService'
import Vue from 'vue'
import ModalVerComanda from '../administracion/dashboard/componentes/ModalVerComanda.vue' 
import ModalMesas from '../comanda/dashboard/componentes/ModalMesas.vue'
import ModalFormaPago from './componentes/ModalFormaPago.vue'
import MaestroService from '@/api/servicios/MaestroService'
import Fiscalizar from '@/funciones/fiscalizar'
import funciones from '@/funciones/funciones'
// demos

export default {
  components: {
    FormasPagos,
    KeyBoardEvents,
    BuscarProducto,
    ModalConfirmacion,
    ModalCliente,
    ListaDetallesProductosDetalle,
    BuscarComandas,
    ModalFormaPago,
    ModalVerComanda,
    ModalMesas
  },
  methods: {
    keyboardEvent(e) {
      let tecla = e.which
      if (this.validarKeyEnable() == true) {
        console.log(e.which)

        if (e.altKey && tecla == 76) {
          e.preventDefault()
          e.stopPropagation()
          this.cancelarFactura()
          return false
        }
        if (tecla == 46 && this.focusSelect > -1) {
          e.preventDefault()
          e.stopPropagation()
          this.GetEliminarProducto(this.comanda.productos[this.focusSelect])
          return false
        }
        if (e.altKey && tecla === 70) {
          e.preventDefault()
          e.stopPropagation()
          if (this.validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)) {
            this.AbrirComandaAntigua()
            return false
          }
        }
        if (tecla == 115) {
          e.preventDefault()
          e.stopPropagation()
          this.abrirBuscarComandas()
          return false
        }
        if (tecla == 117) {
          e.preventDefault()
          e.stopPropagation()
          this.dialogFormaPago = true
          return false
        }
        if (tecla == 118) {
          e.preventDefault()
          e.stopPropagation()
          this.dialogMesa = true 
          return false
        }
        if (tecla == 123) {
          e.preventDefault()
          e.stopPropagation()
          this.GetGuardarComanda()
          return false
        }
        if (tecla == 116) {
          e.preventDefault()
          e.stopPropagation()
          this.abrirCliente()
          return false
        }
        if (tecla == 113) {
          e.preventDefault()
          e.stopPropagation()
          this.dialogDescuento = true
          return false
        }
        if (tecla == 114) {
          e.preventDefault()
          e.stopPropagation()
          this.dialogServicio = true
          return false
        }
        if (tecla == 112) {
          e.preventDefault()
          e.stopPropagation()
          this.dialogProductos = true
          return false
        }
        if (tecla == 13) {
          e.preventDefault()
          e.stopPropagation()
          this.focusSelect = -1
          this.BuscarProductoRef.focus()
          return false
        }

        if (tecla == 38) {
          e.preventDefault()
          e.stopPropagation()
          console.log(this.focusSelect)
          if (this.focusSelect != -1) {
            if (this.focusSelect >= 1) {
              const inputNew = this.$refs['fieldId_' + (Number(this.focusSelect) - 1)]
              if (inputNew) {
                console.log(inputNew[0])
                inputNew[0].focus()
                this.focusSelect = this.focusSelect - 1
              }
            } else {
              const inputNew = this.$refs['fieldId_0']
              if (inputNew) {
                console.log(inputNew[0])
                inputNew[0].focus()
                this.focusSelect = 0
              }
            }
          } else {
            const inputNew = this.$refs['fieldId_' + (Number(this.comanda.productos.length) - 1)]
            if (inputNew) {
              console.log(inputNew[0])
              inputNew[0].focus()
              this.focusSelect = Number(this.comanda.productos.length) - 1
            }
          }

          return false
        }
        if (tecla == 40) {
          console.log(this.focusSelect)
          e.preventDefault()
          e.stopPropagation()

          const inputNew = this.$refs['fieldId_' + (Number(this.focusSelect) + 1)]
          console.log()
          if (inputNew) {
            console.log(inputNew[0])
            inputNew[0].focus()
            this.focusSelect = this.focusSelect + 1
          }

          return false
        }
      }
      // Evitar el comportamiento por defecto del nevagador:

      // Mostrar el resultado de la combinación de las teclas:
    },
  },

  setup() {
    const mdiInvoiceText = 'M3 3V22L6 20L9 22L12 20L15 22L18 20L21 22V3H3M17 7V9H7V7H17M15 11V13H7V11H15Z'
    const BuscarComandasRef = ref(null)
    const dialogServicio = ref(false)
    const _por_servicio = ref(0)
    const dialogDescuento = ref(false)
    const _por_descuento = ref(0)
    const dialogFormaPago = ref(false)
    const dialogMesa = ref(false)
    onBeforeMount(() => {
      limpiar()
    })

    const ImprimirGrupoPago = comanda => {
      MaestroService.imprimirGrupoTikeraPagos({
        id_comanda: comanda.id,
        id_grupo_impresora_pc: store.state.tikera.id,
      })
        .then(response => {
          console.log(response.data)
          if (response.data.mensaje == 'guardado con exito') {
            store.commit('setAlert', {
              message: `Se envio a la impresora.`,
              type: 'success',
            })
          } else {
            store.commit('setAlert', {
              message: `Ups, ${response.data.mensaje}`,
              type: 'info',
            })
          }
        })
        .catch(error => {
          store.commit('setAlert', {
            message: `Ups, ${error}`,
            type: 'error',
              error: {
                        ...error, 
                        response: error?.response?.data
                    },
                funcion: 'ImprimirGrupoPago',
          })
        })
    }

    const comprobarDuplicidadComanda = async comandaResutl => {
  
      try {
        return ComandaService.nuevaComandaDuplicado(comandaResutl)
          .then(async response => {
            if (response.data.mensaje == 'OK') {
              return 0
            } else if (response.data.mensaje == 'REPETIDO') {
              return await Vue.swal({
                icon: 'warning',
                html:
                  '<h3>Esta ejecutando una marcha exactamente igual a la anterior </h3>' +
                  `¿Seguro que quiere realizar esta marcha?`,
                showCancelButton: true,
                confirmButtonText: 'Si, Seguro',
                cancelButtonText: 'No',
                preConfirm: async () => {
                  return true
                },
                allowOutsideClick: () => {
                  !Vue.swal.isLoading()
                },
              }).then(result => {
                if (result.isConfirmed) {
                  return 2
                } else {
                  return 1
                }
                console.log(result)
                //GetFiscalizando(false)
              })
              return 1
            } else {
              store.commit('setAlert', {
                message: 'Error al comprobar duplicado: No se devolvio un valor esperado ',
                type: 'warning',
              })
              return -1
            }
          })
          .catch(error => {
            store.commit('setAlert', {
              message: 'Error al comprobar duplicado ' + error,
              type: 'error',
              error: {
                        ...error, 
                        response: error?.response?.data
                    },
              funcion: 'comprobarDuplicidadComanda',
            })
            return -1
          })
      } catch (error) {
        store.commit('setAlert', {
          message: 'Error al comprobar duplicado ' + error,
          type: 'error',
          error: error,
          funcion: 'comprobarDuplicidadComanda',
        })
        return false
      }
    }
    function delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    }
    const loadGuardandoComanda = ref(false)

    const GetGuardarComanda = async data => {
      if (comanda.value.productos.length  > 0) {
        if (!loadGuardandoComanda.value) {
        //try {
          if (
            (comanda.value.resta <= 0 && comanda.value.resta != comanda.value.total) ||
            comanda.value.pagos.length == 0
          ) {
            if (store.state.user.caja.id == undefined && comanda.value.pagos.length != 0) {
              store.commit('setAlert', {
                message: 'Disculpe debe tener una caja asignada para poder realizar pagos',
                type: 'warning',
              })
            } else if (
              comanda.value.mesa == 0 &&
              store.state.user.caja.id == undefined &&
              FuncionesGenerales.configuracionSelect(9) == 2
            ) {
              store.commit('setAlert', {
                message: 'Disculpe debe seleccionar una mesa',
                type: 'warning',
              })
            } else if (
              (comanda.value.id_cliente == -1 && FuncionesGenerales.configuracionSelect(10) == 2) ||
              (comanda.value.id_cliente == -1 &&
                comanda.value.pagos.length > 0 &&
                FuncionesGenerales.configuracionSelect(10) == 3)
            ) {
              store.commit('setAlert', {
                message: 'Disculpe debe seleccionar un cliente',
                type: 'warning',
              })
            } else {
              loadGuardandoComanda.value = true
              await delay(500)
              let comandaResutl = { ...comanda.value }
              comandaResutl.items = comandaResutl.productos.length
              comandaResutl.comandero = store.state.user.id_empleado
              comandaResutl.caja = store.state.user.caja.id
              comandaResutl.cajero = store.state.user.id_empleado
              comandaResutl.productos = comanda.value.productos.filter(item => item.id < 0 || item.ind_modifica)
              comandaResutl.id_grupo_impresora_pc = store.state.tikera.id
              if (!validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA)) {
                comandaResutl.mesonero = store.state.user.id_empleado
              }

              console.log(comandaResutl)

              const duplicado = await comprobarDuplicidadComanda(comandaResutl)

              if (duplicado == 0 || duplicado == 2) {
                ComandaService.nuevaComanda(comandaResutl)
                  .then(response => {
                    console.log(response)
                    if (
                      response.data.mensaje == 'COMANDA_GUARDADA_CON_EXITO' ||
                      response.data.mensaje == 'COMANDA_PAGADA_CON_EXITO' ||
                      response.data.mensaje == 'COMANDA_PAGO_PARCIAL_CON_EXITO' ||
                      response.data.mensaje == 'COMANDA_MODIFICADA_CON_EXITO'
                    ) {
                      if (
                        response.data.mensaje == 'COMANDA_PAGADA_CON_EXITO' ||
                        response.data.mensaje == 'COMANDA_PAGO_PARCIAL_CON_EXITO'
                      ) {
                        ImprimirGrupoPago(response.data.datos)
                        //procesarTiketsEstacionamiento(comandaResutl)
                        fiscalizar(response.data.datos)
                        console.log('getNumeroComanda 1')
                        console.log(response.data.datos)
                        ///context.emit('getNumeroComanda', response.data.datos)
                      }

                      limpiar()
                      loadGuardandoComanda.value = false

                      store.commit('setAlert', {
                        message: response.data.mensaje,
                        type: 'primary',
                      })

                    
                    } else {
                      loadGuardandoComanda.value = false
                      store.commit('setAlert', {
                        message: response.data.mensaje,
                        type: 'warning',
                      })
                    }
                  })
                  .catch(error => {
                    loadGuardandoComanda.value = false
                    console.error(error)
                    store.commit('setAlert', {
                      message: error,
                      type: 'error',
                      error: {
                        ...error, 
                        response: error?.response?.data
                    },
                      funcion: 'comprobarDuplicidadComanda',
                    })

                    
                  })
              } else {
                loadGuardandoComanda.value = false
              }
            }
          } else {
            store.commit('setAlert', {
              message: 'Disculpe los pagos no cubren el total de la factura',
              type: 'warning',
            })
          }
        /*} catch (error) {
          loadGuardandoComanda.value = false
          store.commit('setAlert', {
            message:
              'Error general al guardar la comanda. si este error persiste comunicate con el administrador de sistemas',
            type: 'error',
          })
        }*/
      }
      }else{
        store.commit('setAlert', {
                message: 'Debe tener por lo menos un producto agregado',
                type: 'warning',
              })
      }
     
    }
    const fiscalizar = async datos => {

      if (funciones.configuracionSelect('21') == '2') {
      ModalConfirmacionRef.value.abrir({
        icon:mdiPrinter,
        subTitulo: '<h3>¿Generar factura fiscal? </h3>',
        btnConfirmar: {
          esperarRespuesta: true,
          texto: 'Si',
          color: 'primary',
          funcionDatos: {
            item: datos,
            _MonedaPais: { ...MonedaPais.value, tasa: Number(datos.tasa_modeda_pais) },
            _serialFiscal: serialFiscal.value,
            _ind_devolucion: false,
          },
          funcion: async _dat => {
            ModalConfirmacionRef.value.cerrar()
            await Fiscalizar.Fiscalizar(_dat)
            
          },
        },
        btnCancelar: {
          texto: 'No',
          color: 'secondary',
          funcion: () => {},
        },
      })}
      else if (funciones.configuracionSelect('21') == '1'){
        await Fiscalizar.Fiscalizar(
          {
            item: datos,
            _MonedaPais: { ...MonedaPais.value, tasa: Number(datos.tasa_modeda_pais) },
            _serialFiscal: serialFiscal.value,
            _ind_devolucion: false,
          }
        )
      }
    }

    const comandaSelect = ref(0)
    const openModalComanda = ref(false)

    const abrirModalComandaAntigua = () => {

      ModalConfirmacionRef.value.abrir({
        inputs: [
          {
            valor: '', //valor ingresado por el usuario
            nombreObjeto: 'numero', //objeto q se devolvera en la funcion callback
            texto: 'Numero Factura', //valor a postrar en laberl de input
            obligatorio: true, //valdiar antes de dar condifrmar
            tipoInput: 'text', //text,number,textArea,select,checkbox
            opciones: [], //validos para select y checkbox
            autofocus: true
          } 
           
        ],
        subTitulo: '<h3>Ingrese el numero de la factura a buscar</h3>',
        btnConfirmar: {
          esperarRespuesta: true,
          texto: 'Buscar',
          color: 'primary',
          funcionDatos: {},
          funcion: async _dat => {
            comandaSelect.value = { id: _dat.numero }
            openModalComanda.value = true
          },
        },
        btnCancelar: {
          texto: 'Cancelar',
          color: 'secondary',
          funcion: () => {},
        },
      })

/*
      Vue.swal({
        icon: 'warning',
        html:
          '<h3>Ingresa el numero de comanda a buscar  </h3>' +
          ` ` +
          ` <input  id="swal-motivo-anulacion"   class="swal2-input" placeholder="Numero Comanda">  `,
        showCancelButton: true,
        confirmButtonText: 'Si, Seguro',
        showLoaderOnConfirm: true,
        cancelButtonText: 'No',
        preConfirm: async () => {
          const numero = document.getElementById('swal-motivo-anulacion').value

          if (numero != undefined && numero != '' && numero != null && !isNaN(numero)) {
            comandaSelect.value = { id: numero }
            openModalComanda.value = true
            return true
          } else {
            Vue.swal.showValidationMessage(`Debe proporcionar un numero valido`)
          }
        },
        allowOutsideClick: () => {
          !Vue.swal.isLoading()
        },
      }).then(result => {
        //GetFiscalizando(false)
      })*/
    }

    const abrirFormaPago = () => {
      dialogFormaPago.value = true
    }

    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)
      return valor
    }
    const editarProducto = item => {
      ProductoSelect.value = item
      drawer.value = true
    }
    const GetAtrasProductoDetalle = () => {
      drawer.value = false
    }
    const ProductoSelect = ref({})
    const drawer = ref(false)
    const comandaINIT = {
      id_cliente: -1,
      cliente: store.state.user.caja.id == undefined ? '' : 'cliente',
      descripcion_cliente: '',
      por_comision: !(FuncionesGenerales.configuracionSelect(8) == null || store.state.user.caja.ind_servicio == false)
        ? Number(FuncionesGenerales.configuracionSelect(8))
        : 0,
      estado_despacho: false,
      estado_pago: 7,
      forma_pago: 20,
      estado_forma_pago: 20,
      guardando: 0,
      id: 0,
      iniciando: 0,
      items: 0,
      mesa: 0,
      mesonero: validarAcciones(acciones.DASHBOARD_COMANDA_PAGAR_COMANDA) ? 0 : store.state.user.id_empleado,
      nuevo: true,
      productos: [],
      total: 0,
      contador: 1,
      pagos: [],
      descuentoMonedaPais: 0,
      servicioMonedaPais: 0,
      ivaMonedaPais: 0,
      igtfMonedaPais: 0,
      subtotalMonedaPais: 0,
      totalMonedaPais: 0,
      restaMonedaPais: 0,
      totalPagosMonedaPais: 0,
      totalPagos: 0,
      diferencia: 0,
      servicio: 0,
      RecargoivaMonedaPais: 0,
      RecargoIva: 0,
      numero_comensales: 0,
      propinas: [],
      ind_exonerar_iva: false,
      por_descuento: 0,
      mesa_nombre: 'Sin Mesa',
      mesonero_nombre: 'Sin Asignar',
    }
    const ModalClienteRef = ref()
    const mdiStickerTextOutline =
      'M18.5 2H5.5C3.6 2 2 3.6 2 5.5V18.5C2 20.4 3.6 22 5.5 22H16L22 16V5.5C22 3.6 20.4 2 18.5 2M6 7H18V9H6V7M6 11H18V13H6V11M13 17H6V15H13V17M15 20V18.5C15 16.6 16.6 15 18.5 15H20L15 20Z'
    const mdiInvoiceTextCheck =
      'M3 3V22L6 20L9 22L12 20L13.3 20.86C13.1 20.28 13 19.65 13 19C13 15.69 15.69 13 19 13C19.7 13 20.37 13.12 21 13.34V3H3M17 7V9H7V7H17M15 11V13H7V11H15M15.5 19L18.25 22L23 17.23L21.84 15.82L18.25 19.41L16.66 17.82L15.5 19Z'
    const BuscarProductoRef = ref(null)
    const Modificar = ref(false)
    const configuraciones = ref([])
    const monedas = ref([])
    const mesas = ref([])
    const formasPagos = ref([])
    const estadoFormaPago = ref([])
    const ModalConfirmacionRef = ref()
    const comanda = ref(JSON.parse(JSON.stringify(comandaINIT)))
    const mesoneros = ref([])
    const focusSelect = ref(-1)
    const dialogBtns = ref(false)
    const dialogProductos = ref(false)
    const keyEnable = ref(true)
    const GetCambiarPorDescuentoGeneral = async valor => {
      if (Number(valor) >= 0 && Number(valor) < 100) {
        dialogDescuento.value = false

        var todos = false
        if (comanda.value.productos.filter(it => it.por_descuento != comanda.value.por_descuento).length > 0) {
          await Vue.swal({
            html:
              '<h3>¿Realizar cambio a todo? </h3>' +
              `<p>hay productos que tienen un porcentaje de descuento individual diferente ¿quiere reemplazarlos?</p>`,

            showCancelButton: true,
            confirmButtonText: 'Aplicar a todos',
            showLoaderOnConfirm: true,
            cancelButtonText: 'Cancelar',
            showDenyButton: true,
            denyButtonText: 'Aplicar al resto',
            denyButtonColor: '#36dd35',

            preConfirm: () => {
              return true
            },
            allowOutsideClick: () => {
              if (!Vue.swal.isLoading()) {
                Vue.swal.close()
                elimiandoItem.value = false
              }
            },
          }).then(result => {
            console.log(result)
            if (result.isConfirmed) {
              comanda.value.productos.forEach(item => {
                if (item.por_descuento != comanda.value.por_descuento) {
                  item.ind_modifica = true
                }
                item.por_descuento = valor
              })
              comanda.value.por_descuento = valor

              Totales()
            } else if (result.isDenied) {
              comanda.value.productos.forEach(item => {
                if (item.por_descuento == comanda.value.por_descuento) {
                  item.por_descuento = valor
                }
              })
              comanda.value.por_descuento = valor
              Totales()
            }
            return null
          })
        } else {
          comanda.value.productos.forEach(item => {
            item.por_descuento = valor
          })
          comanda.value.por_descuento = valor
          Totales()
        }
      }
    }

    const abrirBuscarComandas = () => {
      BuscarComandasRef.value.abrir(monedas.value, configuraciones.value)
    }
    const GetCambiarComision = (valor = 0) => {
      if (Number(valor) >= 0 && Number(valor) < 100) {
        dialogServicio.value = false

        comanda.value.por_comision = valor
        Totales()
      }
    }
    onBeforeMount(() => {
      CargarDatosGenerales()
    })
    const limpiar = () => {
      comanda.value = JSON.parse(JSON.stringify(comandaINIT))
      Totales()
    }
    watch(comanda, () => {
      store.commit('setComandaActual', comanda.value)
    })
    const GetEliminarProducto = data => {
      console.log('GetEliminarProducto', data)
      let item = data
      if (data.id < 0) {
        comanda.value.productos = comanda.value.productos.filter(item => item.id != data.id)
        Totales()
      } else {
        if (comanda.value.estado_pago !== 9) {
          // vaidarPermiso(acciones.DASHBOARD_ADMINISTRACION_ANULAR_COMANDA_DETALLE, eliminarItemGuardado, data)
          ConfirmacionUsuarioRef.value.abrir(
            { id_accion_modulo: acciones.DASHBOARD_ADMINISTRACION_ANULAR_COMANDA_DETALLE },
            eliminarItemGuardado,
            data,
            () => {},
          )
        } else {
          store.commit('setAlert', {
            message: 'La comanda esta cerrada',
            type: 'warning',
          })
        }
      }
    }
    const abrirCliente = () => {
      ModalClienteRef.value.CambiarEstadoDialogo(true)
    }
    const GetClienteFull = datos => {
      comanda.value.documento = datos.documento
      comanda.value.nombre_cliente = datos.nombre
      comanda.value.apellido_cliente = datos.apellido
      comanda.value.id_cliente = datos.id
    }
    const cancelarFactura = () => {
      ModalConfirmacionRef.value.abrir({
        subTitulo: '<h3>¿Seguro que quiere cancelar la factura? </h3>',
        btnConfirmar: {
          esperarRespuesta: true,
          texto: 'Si',
          color: 'primary',
          funcionDatos: {},
          funcion: async _dat => {
            limpiar()
          },
        },
        btnCancelar: {
          texto: 'No',
          color: 'secondary',
          funcion: () => {},
        },
      })
    }

    const validarKeyEnable = () => {
      var valor = true
      if (dialogProductos.value) {
        valor = false
      }
      if (ModalClienteRef.value.dialog) {
        valor = false
      }
      if (dialogServicio.value) {
        valor = false
      }
      if (dialogDescuento.value) {
        valor = false
      }
      if (dialogFormaPago.value) {
        valor = false
      }
      if (openModalComanda.value) {
        valor = false
      }
      if (ModalConfirmacionRef.value.dialog){
        valor = false
      }

      return valor
    }

    const productoComandaINIT = {
      id: 0,
      cantidad: 1,
      estado_despacho: 0,
      extras: [],
      total_extra: 0,
      nuevo: true,
      observacion: '',
      precio_total: 0,
      precio_real: 0,
      descuento: 0,
      por_descuento: 0,
      ind_servicio_detalle: true,
      producto: {
        activo_venta: true,
        categoria_nombre: 'COMIDAS',
        checkbox: false,
        costo: 1,
        descripcion: 'PARRILLA DE POLLO',
        estado: 1,
        estado_descripcion: 'ACTIVO',
        extra: false,
        extras: null,
        id: 619,
        id_categoria: 1,
        id_producto_tipo: 1,
        id_puesto: 14,
        img: 'PRODUCTO_FAIB873SFG.JPEG',
        ingredientes: null,
        nombre: 'PARRILLA DE POLLO',
        precio_venta: 7.5,
        ptipo_descripcion: 'PRODUCTO PRINCIPAL',
        sub_categoria: 10,
        sub_categoria_nombre: 'PINCHOS',
        und_caja: 0,
        unidad_medida: 5,
        utilidad: 0.3,
      },
    }
    let contador = -10000
    const idDefaultProducto = () => {
      contador = contador + 1
      return contador
    }
    const GetEditarProducto = data => {
      if (data.id < 0) {
        const producto = {
          cantidad: data.cantidad,
          estado_despacho: 0,
          extras: data.extras,
          total_extra: data.total_extra,
          nuevo: true,
          observacion: data.observacion,
          ingredientes: data.ingredientes,
          ingredientes_variables: data.ingredientes_variables,
          precio_real: data.precio_real,
          precio_total: data.precio_total,
          producto: data.producto.producto,
          descuento: data.producto.descuento,
          por_descuento: data.producto.por_descuento,
          id: data.id,
          sub_total: data.precio_real * data.cantidad,
          precio_venta: data.precio_venta,
          iva: data.iva,
          id_empleado_responsable: data.id_empleado_responsable,
          ind_servicio_detalle: data.producto.producto.ind_servicio,
          ind_modifica_precio: data.ind_modifica_precio,
          id_producto: data.producto.producto.id,
        }
        var comandaAux = comanda.value.productos.filter(item => item.id != data.id)
        comanda.value.productos = [...comandaAux, producto]
      } else {
        if (comanda.value.ind_bloqueado != true) {
          const producto = {
            id: idDefaultProducto(),
            cantidad: data.cantidad,
            estado_despacho: 0,
            extras: data.extras,
            total_extra: data.total_extra,
            nuevo: true,
            observacion: data.observacion,
            ingredientes: data.ingredientes,
            ingredientes_variables: data.ingredientes_variables,
            precio_real: data.precio_real,
            precio_total: data.precio_total,
            producto: data.producto.producto,
            descuento: data.producto.descuento,
            por_descuento: data.producto.por_descuento == 0 ? comanda.value.por_descuento : data.producto.por_descuento,
            sub_total: data.precio_real * data.cantidad,
            precio_venta: data.precio_venta,
            iva: data.iva,
            id_empleado_responsable: data.id_empleado_responsable,
            ind_servicio_detalle: data.producto.producto.ind_servicio,
            ind_modifica_precio: false,
            id_producto: data.producto.producto.id,
          }
          comanda.value.productos = [...comanda.value.productos, producto]
        } else {
          store.commit('setAlert', {
            message: 'Disculpe la comanda se encuentra bloqueada',
            type: 'warning',
          })
        }
      }

      Totales()

      drawer.value = false
    }
    const GetAgregarProducto = _data => {
      dialogProductos.value = false
      //alert('GetAgregarProducto')
      console.log('GetProducto')
      const producto = Object.assign({}, { ...productoComandaINIT, producto: _data })
      const data = {
        ingredientes: [],
        extras: [],
        ingredientes_variables: [],
        cantidad: 1,
        observacion: '',
        producto: producto,
        total_extra: 0,
        precio_total: producto.producto.precio_venta,
        precio_real: producto.producto.precio_venta,
        precio_venta: producto.producto.precio_venta,
        iva: round(producto.producto.precio_venta * (producto.producto.impuesto_tasa_valor / 100)),
        id: producto.id,
        id_empleado_responsable: -1,
      }

      if (data.id < 0) {
        const producto = {
          cantidad: data.cantidad,
          estado_despacho: 0,
          extras: data.extras,
          total_extra: data.total_extra,
          nuevo: true,
          observacion: data.observacion,
          ingredientes: data.ingredientes,
          ingredientes_variables: data.ingredientes_variables,
          precio_real: data.precio_real,
          precio_total: data.precio_total,
          producto: data.producto.producto,
          descuento: data.producto.descuento,
          por_descuento: data.producto.por_descuento,
          id: data.id,
          sub_total: data.precio_real * data.cantidad,
          precio_venta: data.precio_venta,
          iva: data.iva,
          id_empleado_responsable: data.id_empleado_responsable,
          ind_servicio_detalle: data.producto.producto.ind_servicio,
          ind_modifica_precio: data.ind_modifica_precio,
          id_producto: data.producto.producto.id,
        }
        var comandaAux = comanda.value.productos.filter(item => item.id != data.id)
        comanda.value.productos = [...comandaAux, producto]
      } else {
        if (comanda.value.ind_bloqueado != true) {
          const producto = {
            id: idDefaultProducto(),
            cantidad: data.cantidad,
            estado_despacho: 0,
            extras: data.extras,
            total_extra: data.total_extra,
            nuevo: true,
            observacion: data.observacion,
            ingredientes: data.ingredientes,
            ingredientes_variables: data.ingredientes_variables,
            precio_real: data.precio_real,
            precio_total: data.precio_total,
            producto: data.producto.producto,
            descuento: data.producto.descuento,
            por_descuento: data.producto.por_descuento == 0 ? comanda.value.por_descuento : data.producto.por_descuento,
            sub_total: data.precio_real * data.cantidad,
            precio_venta: data.precio_venta,
            iva: data.iva,
            id_empleado_responsable: data.id_empleado_responsable,
            ind_servicio_detalle: data.producto.producto.ind_servicio,
            ind_modifica_precio: false,
            id_producto: data.producto.producto.id,
          }
          comanda.value.productos = [...comanda.value.productos, producto]
        } else {
          store.commit('setAlert', {
            message: 'Disculpe la comanda se encuentra bloqueada',
            type: 'warning',
          })
        }
      }

      Totales()
    }

    const Totales = () => {
      let descuento = 0.0
      let servicio = 0.0
      let iva = 0.0
      let igtf = 0.0
      let subtotal = 0.0
      let total = 0.0
      let resta = 0.0
      let totalPagos = 0.0

      let descuentoMonedaPais = 0.0
      let servicioMonedaPais = 0.0
      let ivaMonedaPais = 0.0
      let igtfMonedaPais = 0.0
      let subtotalMonedaPais = 0.0
      let totalMonedaPais = 0.0
      let restaMonedaPais = 0.0
      let totalPagosMonedaPais = 0.0
      let totalPagosMoneda = 0.0
      let RecargoivaMonedaPais = 0.0
      let RecargoIva = 0.0
      let RecargoMonedaPais = 0.0
      let Recargo = 0.0

      console.log('MonedaPais')
      console.log(MonedaPais)
      console.log('MonedaPais')
      comanda.value.productos.forEach(element => {
        const PrecioRealMonedaPais_ = round(element.precio_real * MonedaPais.value.tasa)
        const PrecioTotalMonedaPais_ = round(PrecioRealMonedaPais_ * element.cantidad)

        const descuentoMonedaPais_ = round(PrecioTotalMonedaPais_ * (element.por_descuento / 100))
        let recargoMonedaPais_ = 0
        if (element.ind_servicio_detalle) {
          recargoMonedaPais_ = (PrecioTotalMonedaPais_ - descuentoMonedaPais_) * (comanda.value.por_comision / 100)
        }
        const ivaMonedaPais_ =
          (PrecioTotalMonedaPais_ - descuentoMonedaPais_) *
          (!comanda.value.ind_exonerar_iva ? element.producto.impuesto_tasa_valor / 100 : 0)

        subtotalMonedaPais += PrecioTotalMonedaPais_
        descuentoMonedaPais += descuentoMonedaPais_
        ivaMonedaPais += ivaMonedaPais_
        RecargoMonedaPais += recargoMonedaPais_

        const PrecioReal_ = round(element.precio_real)
        const PrecioTotal_ = round(PrecioReal_ * element.cantidad)
        const descuento_ = round(PrecioTotal_ * (element.por_descuento / 100))
        let recargo_ = 0
        if (element.ind_servicio_detalle) {
          recargo_ = (PrecioTotal_ - descuento_) * (comanda.value.por_comision / 100)
        }
        const iva_ =
          (PrecioTotal_ - descuento_) *
          (!comanda.value.ind_exonerar_iva ? element.producto.impuesto_tasa_valor / 100 : 0)
        descuento += descuento_
        iva += iva_
        subtotal += PrecioTotal_
        Recargo += recargo_
      })
      console.log('aqui es totalPagos')
      console.log(comanda.value)
      comanda.value.pagos.forEach(element => {
        const totalPagosMonedaPais_ = round(round(element.monto_real) * MonedaPais.value.tasa)
        const totalPagos_ = round(element.monto_real)
        const totalPagosMoneda_ = round(element.monto_moneda)

        const igtfMonedaPais_ = element.igtf * MonedaPais.value.tasa
        const igtf_ = element.igtf

        totalPagosMonedaPais += totalPagosMonedaPais_
        totalPagos += totalPagos_
        totalPagosMoneda += totalPagosMoneda_
        igtfMonedaPais += igtfMonedaPais_
        igtf += igtf_
      })

      servicioMonedaPais = round(RecargoMonedaPais)
      totalMonedaPais = round(
        subtotalMonedaPais - descuentoMonedaPais + ivaMonedaPais + igtfMonedaPais + servicioMonedaPais,
      )
      restaMonedaPais = round(totalMonedaPais - totalPagosMonedaPais)

      servicio = Recargo
      total = round(subtotal - descuento + iva + igtf + servicio)
      resta = total - totalPagos
      console.log('servicio aquii')
      console.log(round((subtotal - descuento) * (comanda.value.por_comision / 100)))
      const diferencia = round(total * MonedaPais.value.tasa - totalMonedaPais)

      servicioMonedaPais = round(RecargoMonedaPais + diferencia)
      totalMonedaPais = round(
        subtotalMonedaPais - descuentoMonedaPais + ivaMonedaPais + igtfMonedaPais + servicioMonedaPais,
      )
      restaMonedaPais = round(totalMonedaPais - totalPagosMonedaPais)
      console.log('totales')
      console.log({
        descuento,
        servicio,
        iva,
        igtf,
        total,
        totalPagos,
        resta,
        subtotal,
        descuentoMonedaPais,
        servicioMonedaPais,
        ivaMonedaPais,
        igtfMonedaPais,
        subtotalMonedaPais,
        totalMonedaPais,
        restaMonedaPais,
        totalPagosMonedaPais,
        diferencia,
        RecargoMonedaPais,
        Recargo,
      })
      comanda.value = {
        ...comanda.value,
        descuento,
        servicio,
        iva,
        igtf,
        total,
        totalPagos,
        resta,
        subtotal,
        descuentoMonedaPais,
        servicioMonedaPais,
        ivaMonedaPais,
        igtfMonedaPais,
        subtotalMonedaPais,
        totalMonedaPais,
        restaMonedaPais,
        totalPagosMonedaPais,
        diferencia,
        RecargoMonedaPais,
        Recargo,
      }
    }

    const totalesPropina = () => {
      let sumas = {
        totalMonedaPais: 0,
        totalMoneda: 0,
      }

      comanda.value.propinas.forEach(element => {
        sumas.totalMonedaPais += Number(element.monto_real) * MonedaPais.value.tasa
        sumas.totalMoneda += Number(element.monto_real)
      })

      return sumas
    }

    const MonedaPais = ref({})
    const cargadoConfiguracion = ref(0)
    const CargarDatosGenerales = () => {
      cargadoConfiguracion.value = 0
      ComandaService.obtenerDatosGeneralesComanda()
        .then(response => {
          cargadoConfiguracion.value = 1
          console.log(response)
          mesas.value = response.data.datos.mesas
          formasPagos.value = response.data.datos.forma_pago
          mesoneros.value = response.data.datos.mesoneros
          monedas.value = response.data.datos.monedas
          configuraciones.value = response.data.datos.configuraciones
          estadoFormaPago.value = response.data.datos.estado_forma_pago
          MonedaPais.value = monedas.value.find(item => item.meneda_pais == true)
        })
        .catch(error => {
          cargadoConfiguracion.value = 2
          console.log(error)
        })
    }
    function round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15))
      return (Math.round(m) / 100) * Math.sign(num)
    }
    const rules = {
      required: value => !!value || 'Required.',
      min: v => (Number(v) < 100 && Number(v) >= 0) || 'debe ser un valor entre 0 y 99',
      min2: v => Number(v) > 0 || 'debe ser un valor mayor a 0',
    }
    const TextReferencia = ref('')
    const TextReferenciaLoading = ref(false)
    const buscarProductoReferencia = () => {
      if (TextReferencia.value) {
        TextReferenciaLoading.value = true
        ProductosService.listarProductoBuscarPuesto({ referencia: TextReferencia.value })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              if (response.data.datos && response.data.datos.length > 0) {
                GetAgregarProducto(response.data.datos[0])
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            TextReferenciaLoading.value = false
            TextReferencia.value = ''
          })
      }
    }

    const GetVerComanda = comandaIn => {
      if (comandaIn == null) {
        comanda.value = JSON.parse(JSON.stringify(comandaINIT))
      } else {
        comanda.value = JSON.parse(JSON.stringify(comandaIn))
      }

      Totales()
    }

    const GetEliminarPagos = datos => {
      console.log('GetEliminarPagos3', datos)
      if (datos.id < 0) {
        let pagos = comanda.value.pagos.filter(item => item.id != datos.id)
        comanda.value.pagos = [...pagos]
        if (datos.elimina_propina) {
          let pagosPropinas = comanda.value.propinas.filter(item => item.id_pago_propina_vuelto != datos.id)
          console.log('pagosPropinas', pagosPropinas)
          comanda.value.propinas = [...pagosPropinas]
        }
      } else {
        store.commit('setAlert', {
          message: 'No puede eliminar un pago ya registrado, si desea eliminarlo debe ser por un administrador',
          type: 'warning',
        })
      }
      Totales()
    }

    const configuracionesFun = () => {
      return {
        igtf: !store.state.user.caja.ind_igtf ? 0 : Number(FuncionesGenerales.configuracionSelect(2)),
      }
    }
    const GetPagos = datos => {
      console.log('Pago nuevo')
      console.log(datos)
      let igtf = 0
      let error = 0
      const tipo_pago_select = datos.moneda.tipo_pago.find(item => item.id == datos.tipo_pago)
      let vuelto_info = {}
      //calculo igtf
      if (tipo_pago_select.ind_igtf) {
        console.log('tipo_pago tipo_pago', tipo_pago_select)
        if (comanda.value.total - comanda.value.igtf - comanda.value.totalPagos >= Number(datos.monto_real)) {
          igtf = Number(datos.monto_real) * (configuracionesFun().igtf / 100)
        } else {
          if (comanda.value.total - comanda.value.igtf - comanda.value.totalPagos >= 0) {
            igtf =
              (comanda.value.total - comanda.value.igtf - comanda.value.totalPagos) * (configuracionesFun().igtf / 100)
          } else {
            store.commit('setAlert', {
              message:
                'Ya completo el monto de la factura 1 total' +
                comanda.value.total +
                ' igtf' +
                comanda.value.igtf +
                ' totalPagos' +
                comanda.value.totalPagos,
              type: 'info',
            })
            error = 1
            console.log('error')
          }

          console.log('vuelto')
          const vuelto = datos.monto_real - (igtf + comanda.value.resta)
          vuelto_info = {
            moneda: datos.moneda,
            monto_real: round(vuelto),
            monto_moneda: round(vuelto * datos.moneda.tasa),
            mostrar: 1,
            tipo_pago: datos.tipo_pago,
          }
        }
        console.log('Pago nuevo igtf')
        console.log(vuelto_info)
      } else {
        if (comanda.value.total - comanda.value.totalPagos < 0) {
          store.commit('setAlert', {
            message: 'Ya completo el monto de la factura 2',
            type: 'info',
          })
          error = 1
          console.log('error')
        } else {
          console.log('vuelto')
          const vuelto = datos.monto_real - (igtf + comanda.value.resta)
          vuelto_info = {
            moneda: datos.moneda,
            monto_real: round(vuelto),
            monto_moneda: round(vuelto * datos.moneda.tasa),
            mostrar: 1,
            tipo_pago: datos.tipo_pago,
          }
        }
      }
      if (error == 0) {
        comanda.value.pagos = [
          ...comanda.value.pagos,
          { ...datos, id: !datos.id == 0 ? datos.id : idDefaultPago(), igtf, vuelto_info },
        ]
        Totales()
      }
    }

    let contadorPago = -10000
    const idDefaultPago = () => {
      contadorPago = contadorPago + 1
      return contadorPago
    }
    const AgregarPropinaVuelto = datos => {
      if (!comanda.value.propinas.find(it => it.id_pago_propina_vuelto != null)) {
        comanda.value.propinas.push(datos)
      } else {
        store.commit('setAlert', {
          message: 'ya existe una pripina de vuelto agregada',
          type: 'info',
        })
      }
    }
    const AgregarPropina = datos => {
      comanda.value.propinas.push({ ...datos, id: idDefaultPago() })
    }

    const EliminarPropina = datos => {
      const arr = comanda.value.propinas.filter(ite => ite.id != datos.id)
      comanda.value.propinas = [...arr]
    }
    const serialFiscal = ref('')
    const serialFiscalCargando = ref(false)
    onBeforeMount(() => {
      if (validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)) {
        obtenerSerialImpresoraFiscal()
      }
    })

    const impresora = { ...store.state.impresoraFiscal }
    const obtenerSerialImpresoraFiscal = () => {
      serialFiscal.value = ''
      serialFiscalCargando.value = true
      ImpresoraService.obtenerInfo(impresora)
        .then(response => {
          serialFiscal.value = response.data.split('|')[0]
        })
        .catch(error => {
          serialFiscal.value = ''
          store.commit('setAlert', {
            message: 'Error al conectar con la impresora fiscal',
            type: 'error',
            error: {
                        ...error, 
                        response: error?.response?.data
                    },
            funcion: 'obtenerSerialImpresoraFiscal',
          })
        })
        .finally(() => {
          serialFiscalCargando.value = false
        })
    }
    const reporteXLoad = ref(false)
    const AbrirComandaAntigua = () => {
      if (validarAcciones(acciones.DASHBOARD_COMANDA_FISCALIZAR_COMANDA)) {
        abrirModalComandaAntigua()
      } else {
        let respuesta = {}
        Vue.swal({
          icon: 'info',
          title: 'Credenciales supervisor',
          html:
            '<input type="text" id="swal-input1" class="swal2-input" placeholder="Usuario"> ' +
            '<input type="password" id="swal-input2" class="swal2-input" placeholder="Clave">',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Cancelar',

          preConfirm: login => {
            const usuario = document.getElementById('swal-input1').value
            const clave = document.getElementById('swal-input2').value

            return SeguridadService.validarCredencialesAccionModulo({
              usuario: usuario,
              clave: clave,
              id_accion_modulo: acciones.DASHBOARD_COMANDA_REPORTE_Z,
            })
              .then(response => {
                console.log(response.data)
                respuesta = response.data.datos
                if (response.data.datos == 'ok') {
                  return response.data.datos
                } else {
                  throw new Error('Usuario o clave inavalida, o el usuario no tiene permiso')
                }
              })
              .catch(error => {
                console.error(error)
                store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'AbrirComandaAntigua',
                })
              })
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then(result => {
          console.log(result)
          if (respuesta == 'ok') {
            if (result.isConfirmed) {
              abrirModalComandaAntigua()
            } else if (result.dismiss === 'cancel') {
              reporteXLoad.value = false
            }
          } else {
            if (result.isConfirmed) {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Usuario o clave inavalida, o el usuario no tiene permiso',
              })
            } else if (result.dismiss === 'cancel') {
              reporteXLoad.value = false
            } else {
              Vue.swal({
                icon: 'error',
                title: 'Oops...',
                text: 'hubo un error',
              })
            }
            reporteXLoad.value = false
          }
        })
      }
    }
    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }

    const GetMesa =(mesaSelect)=>{
    
     comanda.value.mesa = mesaSelect.id 
     comanda.value.mesa_nombre = mesaSelect.nombre 
     comanda.value.id_grupo_mesa = mesaSelect.id_grupo_mesa 
     comanda.value.grupo_mesa =mesaSelect.grupo_mesa 
    }
    return {
      icons: {
        mdiCached,
        mdiDelete,
        mdiBarcode,
        mdiFood,
        mdiDotsHorizontal,
        mdiStickerTextOutline,
        mdiPercentOutline,
        mdiSale,
        mdiInvoiceTextCheck,
        mdiCancel,
        mdiKeyboardF12,
        mdiKeyboardF1,
        mdiKeyboardF2,
        mdiKeyboardF3,
        mdiKeyboardF4,
        mdiKeyboardF5,
        mdiKeyboardF7,
        mdiKeyboardF8,
        mdiKeyboardF9,
        mdiKeyboardF10,
        mdiKeyboardF11,
        mdiKeyboardF6,
        mdiKeyboardEsc,
        mdiKeyboardCaps,
        mdiFileEdit,
        mdiAccountTie,
        mdiTableChair,
        mdiInvoiceText,
        mdiCashRegister,
        
  mdiStorefrontOutline,
mdiEyeOutline
      },
      TextReferencia,
      buscarProductoReferencia,
      FuncionesGenerales,
      cargadoConfiguracion,
      configuraciones,
      monedas,
      mesas,
      formasPagos,
      estadoFormaPago,
      CargarDatosGenerales,
      store,
      comanda,
      MonedaPais,
      validarAcciones,
      Modificar,
      acciones,
      round,
      rules,
      BuscarProductoRef,
      focusSelect,
      totalesPropina,
      dialogBtns,
      dialogProductos,
      keyEnable,
      GetAgregarProducto,
      Totales,
      limpiar,
      ModalConfirmacionRef,
      cancelarFactura,
      abrirCliente,
      ModalClienteRef,
      GetClienteFull,
      TextReferenciaLoading,
      GetEliminarProducto,
      drawer,
      ProductoSelect,
      editarProducto,
      GetAtrasProductoDetalle,
      GetEditarProducto,
      GetCambiarComision,
      dialogServicio,
      _por_servicio,
      GetCambiarPorDescuentoGeneral,
      dialogDescuento,
      _por_descuento,
      GetCambiarPorDescuentoGeneral,
      BuscarComandasRef,
      abrirBuscarComandas,
      GetVerComanda,
      abrirFormaPago,
      dialogFormaPago,
      GetEliminarPagos,
      GetPagos,
      AgregarPropinaVuelto,
      AgregarPropina,
      EliminarPropina,
      validarKeyEnable,
      comandaSelect,
      openModalComanda,
      serialFiscal,
      serialFiscalCargando,
      obtenerSerialImpresoraFiscal,
      AbrirComandaAntigua,
      impresora,
      GetOpenModalComanda,
      dialogMesa,
      GetMesa,
      serialFiscal,
      GetGuardarComanda,
      loadGuardandoComanda,
      mesoneros
    }
  },
}
</script>

<style scoped>
.icon-tecla {
  position: absolute;
  top: 0;
  right: 0;
}
.contenedor-productos {
  height: calc(75vh - 167px);
  overflow-x: hidden;
  overflow-y: auto;
}
.contenedor-totales {
  height: calc(25vh);
  overflow-x: hidden;
  overflow-y: auto;
}

.inputPrice >>> input[type='number'] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.centered-input >>> input {
  text-align: center;
}
</style>
