<template>
  <v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field autofocus hide-details dense outlined label="Filtrar" v-model="filtoComanda"></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-switch
              v-model="switch1"
              v-if="validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA)"
              label="Mis comandas"
            >
            </v-switch>
          </v-col>
        </v-row>
        <v-divider class="my-2" ></v-divider>
        <v-row>
          <v-col cols="12" sm="6" v-for="item in FiltrarComanda()" :key="item.id + 'modal-comanda'">
            <card-comanda
              :monedas="monedas"
              :configuraciones="configuraciones"
              @GetVerComanda="GetVerComanda"
              :comanda="item"
              :mostarAcciones="false"
            >
            </card-comanda>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="dialog = false"> cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import CardComanda from '../../comanda/dashboard/componentes/CardComanda.vue'
import { acciones } from '@/modulos'
import store from '@/store'
import ComandaService from '@/api/servicios/ComandaService'

export default {
  components: {
    CardComanda,
  },
  setup(props, context) {
    const dialog = ref(false)
    const monedas = ref([])
    const configuraciones = ref([])
    const comandas = ref([])
    const filtoComanda = ref('')
    const switch1 = ref(false)
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const abrir = (_monedas, _configuraciones) => {
      dialog.value = true
      monedas.value = _monedas
      configuraciones.value = _configuraciones
      filtoComanda.value = ""
      ListarComanda()
    }

    const GetVerComanda = datos => {
      console.log(datos)
      ComandaService.listarComanda({ id: datos.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            dialog.value = false
            context.emit('GetVerComanda', response.data.datos)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const ListarComanda = () => {
      try {
        const ultimaActualizacion = '20000101'
        const id_comandero = validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA)
          ? -1
          : store.state.user.id_empleado
        ComandaService.listarComandas({ ultima_actualizacion: ultimaActualizacion, id_comandero: id_comandero })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              if (response.data?.datos && response.data?.datos?.length > 0) {
                comandas.value = response.data?.datos
              }
            }
          })
          .catch(error => {
            console.error(error)
            store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: {
                        ...error, 
                        response: error?.response?.data
                    },
                  funcion: 'ListarComanda',
                })
          })
      } catch (error) {
        console.error(error)
        store.commit('setAlert', {
                  message: error,
                  type: 'error',
                  error: error,
                  funcion: 'ListarComanda',
                })
      }
    }

    const FiltrarComanda = () => {
      const valor = comandas.value?.filter(
        item =>
          (filtoComanda.value == '' &&
            item.estado_pago == 7 &&
            ((switch1.value == true && item.id_empleado_mesonero == store.state.user.id_empleado) ||
              switch1.value == false)) ||
          (filtoComanda.value != '' &&
            (item.id.toString().includes(filtoComanda.value) ||
              item.cliente.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              item.mesonero_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              item.mesa_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              item.nombre_cliente?.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              item.apellido_cliente?.toUpperCase().includes(filtoComanda.value.toUpperCase()) ||
              `${item.nombre_cliente} ${item.apellido_cliente}`
                .toUpperCase()
                .includes(filtoComanda.value.toUpperCase()) ||
              item.comandero_nombre.toUpperCase().includes(filtoComanda.value.toUpperCase()))) /*&&
          (
            item.estado_pago == filtoComandaEstadoPago.value.id ||
            (item.estado_fiscalizado == filtoComandaEstadoPago.value.id && item.estado_pago != 14) ||
            (filtoComandaEstadoPago.value.id == 39 && ((item.estado_fiscalizado == 39 && item.estado_pago != 14) || item.estado_fiscalizado == 43)) ||
            (filtoComandaEstadoPago.value.id == 40 && ((item.estado_fiscalizado == 40 || item.estado_fiscalizado == 44)))


          )*/,
      )
      if (validarAcciones(acciones.DASHBOARD_COMANDA_MOSTRAR_TODAS_COMANDA)) {
        const items = valor

        return items
      } else {
        const items = valor?.filter(item => item.id_empleado_mesonero == store.state.user.id_empleado)

        return items
      }
    }
    return {
      monedas,
      configuraciones,
      abrir,
      dialog,
      FiltrarComanda,
      ListarComanda,
      GetVerComanda,
      acciones,
      store,
      validarAcciones,
      filtoComanda ,
      switch1
    }
  },
}
</script>

<style>
</style>