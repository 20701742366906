<template>
  <span>
    <v-dialog v-model="dialogProducuto" width="800">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field autofocus outlined v-model="search" hide-details dense label="Producto"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-simple-table dense height="300px" fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Producto</th>
                  <th class="text-left">Barra</th>
                  <th class="text-right">Precio</th>
                  <th class="text-right">Existencia</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  @click="selectProducto(item)"
                  v-for="(item, index) in items"
                  :key="index"
                  :class="focusSelect == index ? 'grey2' : ''"
                >
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.referencia }}</td>
                  <td class="text-right">{{ item.precio_venta }}</td>
                  <td class="text-right">
                     <div  v-if ="item.receta.variable_maximo == null">
                      <p class="my-0 py-0" v-for="pues in item.puestos" :key="pues.id">
                      <small>{{ pues.nombre}}: {{ pues.existencia}}</small>   
                      </p>
                    </div>
                    <div v-else>
                      <p class="my-0 py-0"  >
                       ∞ 
                      </p>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length == 0">
                  <td colspan="4" class="text-center" v-if="isLoading">
                    <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
                  </td>
                  <td colspan="4" class="text-center" v-else>No hay resultados</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPuesto" width="400">
      <v-card>
        
        <v-card-text>
         <v-row>
          <v-col cols="6" v-for="(item,index) in productoSelect.puestos" :key="index">  
            <v-card @click="seleccionarPuesto(item)">
             
              <v-img
              :src="`${config.api_imagenes}/puesto/img/m-${item.img}`"
              :lazy-src="`${config.api_imagenes}/puesto/img/puesto_default.jpg`"
              class="img-producto"
            />
            <strong class="icon-tecla white--text">  {{ productoSelect.receta.variable_maximo == null ?  item.existencia : '-' }}</strong>
            <h3 class="text-center">{{ item.nombre }}  <small>F{{ index +1 }}</small> </h3>
            </v-card>

          </v-col>
         </v-row>
        </v-card-text>

      </v-card>
    </v-dialog>
    <KeyBoardEvents v-on:keydown="keyboardEvent"></KeyBoardEvents>
  </span>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import KeyBoardEvents from '@/components/KeyBoardEvents.vue'
import ProductosService from '@/api/servicios/ProductosService'
import config from '@/api/config'

export default {
  props: {
    value: Boolean,
  },
  methods: {
    keyboardEvent(e) {
      if (this.dialogProducuto == true) {
        let tecla = e.which 
        if (tecla == 112) {
          e.preventDefault()
          e.stopPropagation()
          if (this.productoSelect.puestos  && this.productoSelect.puestos.length >= 1 ) {
            this.seleccionarPuesto(this.productoSelect.puestos[0])
          } 
          return false
        }
        if (tecla == 113) {
          e.preventDefault()
          e.stopPropagation()
          if (this.productoSelect.puestos  && this.productoSelect.puestos.length >= 2 ) {
            this.seleccionarPuesto(this.productoSelect.puestos[1])
          } 
          return false
        }
        if (tecla == 114) {
          e.preventDefault()
          e.stopPropagation()
          if (this.productoSelect.puestos  && this.productoSelect.puestos.length >= 3 ) {
            this.seleccionarPuesto(this.productoSelect.puestos[2])
          } 
          return false
        }
        if (tecla == 115) {
          e.preventDefault()
          e.stopPropagation()
          if (this.productoSelect.puestos  && this.productoSelect.puestos.length >= 4 ) {
            this.seleccionarPuesto(this.productoSelect.puestos[3])
          } 
          return false
        }

        if (tecla == 116) {
          e.preventDefault()
          e.stopPropagation()
          if (this.productoSelect.puestos  && this.productoSelect.puestos.length >= 5 ) {
            this.seleccionarPuesto(this.productoSelect.puestos[4])
          } 
          return false
        }

        if (tecla == 117) {
          e.preventDefault()
          e.stopPropagation()
          if (this.productoSelect.puestos  && this.productoSelect.puestos.length >= 5 ) {
            this.seleccionarPuesto(this.productoSelect.puestos[4])
          } 
          return false
        }

        
        if (tecla == 118) {
          e.preventDefault()
          e.stopPropagation()
          if (this.productoSelect.puestos  && this.productoSelect.puestos.length >= 6 ) {
            this.seleccionarPuesto(this.productoSelect.puestos[5])
          } 
          return false
        }

        if (tecla == 119) {
          e.preventDefault()
          e.stopPropagation()
          if (this.productoSelect.puestos  && this.productoSelect.puestos.length >= 7 ) {
            this.seleccionarPuesto(this.productoSelect.puestos[6])
          } 
          return false
        }

        if (tecla == 120) {
          e.preventDefault()
          e.stopPropagation()
          if (this.productoSelect.puestos  && this.productoSelect.puestos.length >= 8  ) {
            this.seleccionarPuesto(this.productoSelect.puestos[7])
          } 
          return false
        }
        if (tecla == 38) {
          e.preventDefault()
          e.stopPropagation()
          console.log(this.focusSelect)
          if (this.focusSelect >= 1) {
            this.focusSelect = this.focusSelect - 1
          } else {
            this.focusSelect = 0
          }

          return false
        }
        if (tecla == 40) {
          console.log(this.focusSelect)
          e.preventDefault()
          e.stopPropagation()
          if (this.focusSelect + 1 < this.items.length) {
            this.focusSelect = this.focusSelect + 1
          }
          return false
        }

        if (tecla == 13) {
          console.log(this.focusSelect)
          e.preventDefault()
          e.stopPropagation()
          if (this.items.length > 0 && this.focusSelect >= 0) {
            this.selectProductoIndex(this.focusSelect)
          }

          return false
        }
      }
      // Evitar el comportamiento por defecto del nevagador:

      // Mostrar el resultado de la combinación de las teclas:
    },
  },
  watch: {
    dialogProducuto: function (_new, _old) {
      if (_new != _old) {
        this.retornar()
      }
      if (_new == true) {
        this.search = ''
        this.items = []
      }
    },
    value: function (_new, _old) {
      if (_new != this.dialogProducuto) {
        this.dialogProducuto = _new
      }
    },
  },
  components: { KeyBoardEvents },
  setup(props, context) {
    const dialogPuesto = ref(false)
    const dialogProducuto = ref(false)
    const search = ref('')
    const focusSelect = ref(0)
    const productoSelect = ref({})
    const retornar = () => {
      context.emit('input', dialogProducuto.value)
    }
    watch(search, () => {
      if (search.value.length > 3) {
        Buscar()
      }
    })
    const seleccionarPuesto=(puesto)=>{
     

      context.emit('getProducto', {...productoSelect.value, id_puesto : puesto.id  , nombre_puesto : puesto.nombre } )
      dialogPuesto.value = false
      dialogProducuto.value = false
      productoSelect.value = {}

    }
    const isLoading = ref(false)
    const items = ref([])

    const selectProductoIndex = index => {
      // alert("selectProductoIndex")
      const producto = items.value[index]
  
      if (producto.puestos.length == 1) {
        context.emit('getProducto', {...producto, id_puesto : producto.puestos[0].id,   nombre_puesto : producto.puestos[0].nombre  } )
      }else{
        productoSelect.value = producto
        dialogPuesto.value = true
      } 
    }

    const selectProducto = item => {

      if (item.puestos.length == 1) {
        context.emit('getProducto', {...item, id_puesto : item.puestos[0].id , puesto:item  ,  nombre_puesto : item.puestos[0].nombre } )
      }else{
        productoSelect.value = item
        dialogPuesto.value = true
      } 
    }

    const Buscar = () => {
      // Items have already been loaded
      /*if (items.value.length > 0)
                return*/

      if (isLoading.value == true) return

      isLoading.value = true
      setTimeout(() => {
        // Lazily load input items
        var filtro = { id_producto_tipo: '1' }
        if (search.value) {
          filtro = { ...filtro, valor: search.value }
        }
        /*
        if (asignarPagoductoId) {
          filtro = { ...filtro, valor: asignarPagoductoNombre }
        } */
        ProductosService.listarProductoBuscarPuesto({ ...filtro })
          .then(response => {
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              items.value = response.data.datos
              focusSelect.value = 0
            } else {
              items.value = []
              focusSelect.value = 0
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (isLoading.value = false))
      }, 1000)
    }

    return {
      dialogProducuto,
      retornar,
      search,
      items,
      isLoading,
      focusSelect,
      selectProductoIndex,
      selectProducto,
      dialogPuesto,
      productoSelect,
      seleccionarPuesto,
      config
    }
  },
}
</script>
 <style scoped>
 .icon-tecla {
  position: absolute;
  top: 0;
  right: 0;
}
 </style>